<template>
  <section>
    <!-- Filters Card -->
    <!-- <b-card>
      <b-row>
        <b-col sm="12" md="8" lg="8">
          <b-input-group class="input-group-merge mb-1">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Search" v-model="filters.search" />
          </b-input-group>
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <v-select
            class="mb-1"
            v-model="filters.milestone"
            label="lbl_name"
            :reduce="(lbl) => lbl.lbl_id"
            :options="$store.state.master.milestone"
            aria-placeholder="Milestone"
            placeholder="Milestone"
          />
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <v-select
            class="mb-1"
            v-model="filters.contract_nature"
            :options="contract_natures"
            aria-placeholder="Contract Nature"
            placeholder="Nature of Contract"
          />
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <v-select
            class="mb-1"
            v-model="filters.movie"
            :options="$store.state.master.movies"
            :filter-by="movieFilter"
            label="title"
            :reduce="(movie) => movie.movie_id"
            placeholder="Movie"
          >
            <template #selected-option="data">
              <span> {{ data.full_title }}</span>
            </template>
            <template #option="data">
              <div style="max-width: 100px !important">
                <span>
                  {{ data.full_title }}
                </span>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <v-select
            class="mb-1"
            v-model="filters.vendor"
            :options="$store.state.master.vendors"
            label="company"
            :reduce="(vendor) => vendor.vendor_id"
            placeholder="Customer"
          />
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <flat-pickr
            :config="{
              mode: 'range',
              ...$flatPickrConfig,
            }"
            placeholder="Due Date"
            class="form-control mb-1"
            v-model="filters.duedate"
          />
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <flat-pickr
            :config="{
              mode: 'range',
              ...$flatPickrConfig,
            }"
            placeholder="Payment Date"
            class="form-control mb-1"
            v-model="filters.paymentdate"
          />
        </b-col>
        <b-col sm="6" md="2" lg="2">
          <b-button
            variant="primary"
            class="w-100 mb-1"
            @click="onFiltersChange"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            >Search</b-button
          >
        </b-col>
        <b-col sm="6" md="2" lg="2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="w-100 mb-1"
            @click="clearFilters"
            >Reset</b-button
          >
        </b-col>
      </b-row>
    </b-card> -->

    <b-card no-body v-if="$can('read', 'contract')">
      <b-table
        bordered
        class="position-relative"
        primary-key="id"
        responsive
        :empty-text="tableEmptyText"
        show-empty
        :items="milestonesList"
        :fields="fields"
        hover
      >
        <!-- hover -->
        <template #cell(sr)="data">
          <div style="width: max-content">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
            <br />
            <b-badge variant="light-success"
              >{{ `#C${data.item.contract_nature[0]}${data.item.contract_id}` }}
            </b-badge>
          </div>
        </template>
        <template #cell(titles)="data">
          <div style="width: max-content" v-html="data.value"></div>
        </template>
        <template #cell(amountpaid)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(amountpayable)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(payable)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(paymentdate)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>
        <template #cell(duedate)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>
        <!-- <template #cell(actions)="data">
          <feather-icon
            v-if="$can('update', 'contract') && !data.item.deleted"
            size="16"
            icon="EditIcon"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="Edit"
            class="mr-1 cursor-pointer"
            @click="editMilestone(data.item)"
          />
        </template> -->
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
      </b-table>

      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BBadge,
  BTable,
  BCol,
  BRow,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBTooltip,
  VBToggle,
  BSidebar,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import * as ContractServices from "@/apiServices/ContractServices";
import { contract_natures, labels } from "@/utils/constants";
import { formatCurrency } from "@/utils/helpers";

import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    reportDataItem: {
      type: Object,
    },
  },
  components: {
    BCard,
    BBadge,
    BTable,
    BCol,
    BRow,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BPagination,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BModal,
    vSelect,
    flatPickr,

    BSidebar,
  },
  data() {
    return {
      tableEmptyText: "LOADING...",

      contract_natures,
      // contract_statuses,

      filters: {
        search: "",
        milestone: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        duedate: "",
        paymentdate: "",
      },

      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100, "All"],
      },
      fields: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "company",
          label: "Contact",
        },
        {
          key: "titles",
          label: "Movie",
        },
        {
          key: "milestone_name",
          label: "Name",
        },
        {
          key: "amountpaid",
          label: "Amount Paid",
        },
        {
          key: "amountpayable",
          label: "Amount Payable",
        },
        {
          key: "payable",
          label: "Payable",
        },
        {
          key: "duedate",
          label: "Due Date",
        },
        {
          key: "paymentdate",
          label: "Payment Date",
        },
      ],
      milestonesList: [],
      milestoneData: {},
      showEditMilestone: false,
      selectedContractId: null,
    };
  },
  methods: {
    moment,
    formatCurrency,
    movieFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return (
        (option.unique &&
          option.unique.toString().toLowerCase().indexOf(temp) > -1) ||
        (option.title && option.title.toLowerCase().indexOf(temp) > -1) ||
        (option.international_title &&
          option.international_title.toLowerCase().indexOf(temp) > -1)
      );
    },
    clearFilters() {
      this.filters = {
        search: "",
        status: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        territories: [],
        rights: [],
        languages: [],
        from: "",
        to: "",
      };
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      };
      this.onChange();
    },
    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },
    editMilestone(data) {
      this.showEditMilestone = true;
      this.milestoneData = data;
    },
    async getMilestones() {
      const loader = this.$loading.show();

      try {
        const response = await ContractServices.getAllMilestones({
          limit: this.pagination.limit,
          page: this.pagination.currentPage,
          ...this.filters,
          contract_id: this.reportDataItem
            ? this.reportDataItem.reportContractId
            : null,
        });
        if (!response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }

        this.milestonesList = response.data.data.milestones;

        if (response.data.data.count) {
          this.pagination.totalRows = response.data.data.count;
        }
        if (!this.milestonesList.length)
          this.tableEmptyText = "NO RECORDS FOUND";
      } catch (error) {
        console.error("Error in getting milestones List ", error);
        this.tableEmptyText = "Error in getting milestones";
      } finally {
        loader.hide();
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getMilestones();
        localStorage.setItem(
          "filtersAndPaginationMilestones",
          JSON.stringify({
            filters: this.filters,
            pagination: this.pagination,
          })
        );
      });
    },
    loadMilestonesList() {
      const filtersAndPaginationMilestonesStr = localStorage.getItem(
        "filtersAndPaginationMilestones"
      );
      if (filtersAndPaginationMilestonesStr) {
        const obj = JSON.parse(filtersAndPaginationMilestonesStr);
        this.pagination = obj.pagination;
        this.filters = obj.filters;
      }
      this.getMilestones();
    },
  },
  mounted() {
    this.loadMilestonesList();

    // this.$store.dispatch("master/setVendorsMaster");
    // this.$store.dispatch("master/setMoviesMaster");

    // this.$store.dispatch("master/setLabelMaster", { key: labels.milestone });
  },
};
</script>

<style></style>
